import React from 'react'

const PrivacyPolicy = () => {

    document.title = "Privacy Policy"

    return(
        <div className="privacy-policy background-info">
            <div className="privacy-policy-container black-background">
                <h1>Privacy Policy </h1>
                <h3>Last Updated:  March 28, 2021</h3>
                <p >
                    Ensuring your privacy is protected is crucial to us which is why we do not sell or share any of your information with anyone within the company or any third-party services. The information we collect is only used on the site to better help make the process seamless. This policy will describe what we collect and how we use that information. At Piriji we’re big on transparency, and anything you read here, is exactly how we use the information. We believe the users should know how their information is used.  
                </p>
                <p>
                    Piriji collects the following:
                </p>
                <ul>
                    <li>
                        <p>Username</p>
                    </li>
                    <li>
                        <p>Email Address</p>
                    </li>
                    <li>
                        <p>Password</p>
                    </li>
                    <li>
                        <p>Age</p>
                    </li>
                </ul>
                <p>We currently collect this information to be able to:</p>
                <ul>
                    <li>
                        <p>Send emails / contact you the user, for any particular updates or other needs such as forgetting your password. </p>
                    </li>
                    <li>
                        <p>We do not collect your Date of Birth. We only use it to calculate your age as this application is specifically meant for anyone over the age of 21.</p>
                    </li>
                    <li>
                        <p>Your passwords are not seen by anyone, including admins. Your passwords are kept confidential.</p>
                    </li>
                </ul>
                <p>
                    As of this writing, currently do not use google AdSense but we will in the future. Everyone will receive a notification when the update happens and you will be able to freely opt out. 
                </p>
        </div>
    </div>
    )
}

export default PrivacyPolicy