import React from 'react'

const NavBar = () => {
    return(
        <nav className="navbar">
            <h1>
                <a href="/">Piriji</a>
            </h1>
            <ul className="link">
                <li>
                    <a href="/info/about">About</a>
                </li>
                <li>
                    <a href="/info/how-it-works">How it works</a>
                </li>
                <li>
                    <a href="/info/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                    <a href="/info/about">Blogs</a>
                </li>
                <li>
                    <a href="/info/about">Careers</a>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar