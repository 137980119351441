import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'universal-cookie';
/* Redux*/
import { SETUSER } from '../redux/types';
/* Components */
import Paypal from '../components/Paypal';
import MessagePopup from '../components/MessagePopup'
import Sidebar from '../components/Siderbar'
import Map from '../components/Map'

const SearchPage = (props) => {

    const [donate, setDonate] = useState(false)
    const [closedPopup, setClosedPopup] = useState(true)

    let user = useSelector(state => state.user)
    let base_url = useSelector(state => state.base_url)
    let fetched_user = useSelector(state => state.fetched_user)
    const dispatch = useDispatch()
    const cookies = new Cookies();

    useEffect(() => {
        if(fetched_user && !user){
            props.routerProps.history.push('/')
        }else{
            document.title = props.title
            checkCookie()
        }
    }, [user])

    const checkCookie = () => {
        if(cookies.get('closed')) {
            setClosedPopup(true)
        }else{
            setClosedPopup(false)
        }
    }

    const setCookie = () => {
        const d = new Date();
        d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
        cookies.set('closed', 'true', { 
            path: '/', 
            expire: d.toUTCString(),
            secure: true,
        });
        setClosedPopup(true)
    }

    const handleLogout = () => {
        fetch(`${base_url}/logout`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            credentials: 'include'
        }).then(() => {
            dispatch({type: SETUSER, payload: null, payload2: false, payload3: false})
            props.routerProps.history.push('/')
        })
    }

    const handleSidebar = () => {
        const sidebar = document.querySelector('.sidebar-container')
        const sidebarMenu = document.querySelector('.sidebar-menu')
        sidebar.style.display = 'block'
        const openSidebar = setTimeout(()=>{
            sidebarMenu.style.transform = 'translateX(0%)'
            clearTimeout(openSidebar)
        },10)
    }

    return(
        <div style={{width: '100%',height:'100%'}}>
            <Map handleSidebar={handleSidebar}/>
            <Sidebar setDonate={setDonate} handleLogout={handleLogout}/>
            { !closedPopup && <MessagePopup setCookie={setCookie}/> }
            { donate && <Paypal setDonate={setDonate}/> }
        </div>
    )
}

export default SearchPage