import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from './Table'
import { setUser } from '../redux/action.js'


const SavedBars = (props) => {

    let closeButton, barContainer;
    const [openModal, setOpenModal] = useState(false)
    const [hours, setHours] = useState(null)
    const [headerTitle, setHeaderTitle] = useState(null)
    const user = useSelector(state => state.user)
    let base_url = useSelector(state => state.base_url)
    const dispatch = useDispatch()


    const viewHours = (event) => {
        closeButton.style.display = 'none'
        const savedContainer = document.querySelector('.saved-bars-container')
        savedContainer.style.alignItems = 'flex-end'
        if(user.bars[0] === props.bar){
            props.headerRef.current.style.bottom = '275px'
            props.speechBubbleRef.current.style.bottom = '320px'
        }

        let hoursClicked = event.target.dataset.name
        barContainer.style.height = 'auto'

        if(Object.values(props.bar[hoursClicked]).some(day => day.includes(','))){
            barContainer.style.flex = '0 0 auto'
            barContainer.style.padding = '5px 10px'
        }

        setHours(props.bar[hoursClicked])
        setHeaderTitle(event.target.dataset.header)
        setOpenModal(true)
    }
    
    const closeModal = () => {
        barContainer.style.flex = '0 0 200px'
        barContainer.style.height = '120px'
        barContainer.style.paddingTop = '5px'
        if(props.headerRef.current.style.bottom === '275px'){
            props.headerRef.current.style.bottom = '150px'
            props.speechBubbleRef.current.style.bottom = '180px'
        }
        closeButton.style.display = 'block'
        setOpenModal(false)
        setHeaderTitle(null)
        setHours(null)
    }

    const removeBar = (barID) => {
        fetch(`${base_url}/like`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                bar_id: barID,
                user_id: user.id
            })
        })
        .then(resp => resp.json())
        .then(user => {
            props.updateUser(user.bars.find(bar => bar.id === barID))
            dispatch(setUser(user))
            if(document.querySelectorAll('.saved-bar-card').length){
                const numberOfBarsSaved = document.querySelectorAll('.saved-bar-card').length
                const containerSize = (numberOfBarsSaved * 200) + (numberOfBarsSaved * 15)
                if(containerSize > (window.innerWidth * .95) ){
                    document.querySelector('.saved-bars-container').style.overflow = 'auto'
                }else{
                    document.querySelector('.saved-bars-container').style.overflow = 'unset'
                }
            }
        })
    }
 
    return(
            <div className="saved-bar-card" style={{position: 'relative'}} ref={(component) => barContainer = component}>
                {   
                    !openModal ?
                        <div className="bar-card">
                            <div>
                                <a href={`${props.bar.website_url}`} rel="noreferrer" target="_blank"><h1><u>{props.bar.name}</u></h1></a>
                                <p>{props.bar.address.slice(0, (props.bar.address.length -5))}</p>
                            </div>
                            <div style={{margin: '0 auto'}}>
                                <button onClick={(event) => viewHours(event)} data-name="hours" data-header="Hours of Operations">View Hours</button>
                                {
                                    props.bar.happyhours &&
                                        <button onClick={(event) => viewHours(event)} data-name="happyhours" data-header="Happy Hours">Happy Hours</button>
                                }
                            </div>
                        </div>
                    :
                        <div className="hours-modal">
                            <div>
                                <h1><u>{headerTitle}</u></h1>
                                <i className="times icon" onClick={(event) => closeModal(event)}></i>
                            </div>
                            <Table hours={hours} display={'flex'}/>
                        </div>
                }
                <i onClick={() => removeBar(props.bar.id)} className="times circle outline icon" id={props.bar.id.toString()} ref={(component) => closeButton = component}></i>
            </div>
    )
}

export default SavedBars