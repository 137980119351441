import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setUser } from '../redux/action'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as EmailValidator from 'email-validator';
import Footer from '../components/Footer'


const SignupForm = (props) =>{

    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [password_confirmation, setPasswordConfirmation] = useState(null)
    const [dob, setDob] = useState(null)
    const [error, setError] = useState(null)
    let base_url = useSelector(state => state.base_url)
    let user = useSelector(state => state.user)

    useEffect(()=> {
        document.title = props.title
        window.onresize = function(){
            
        }
        if(user){
            props.routerProps.history.push('/search')
        }
    })

    const handleSignup = (event) => {
        event.preventDefault()
        const validEmail = EmailValidator.validate(email)
        if(password === password_confirmation && validEmail){
            fetch(`${base_url}/signup`,{
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    username: username,
                    email: email,
                    password: password,
                    password_confirmation: password_confirmation,
                    dob: dob
                })
            })
            .then(resp => resp.json())
            .then(user => {
                if(user.error){
                    const form = document.querySelector('.form')
                    form.reset();
                    window.history.pushState({}, document.title, "/signup" );
                    setError(user.error)
                }else{
                    props.setUser(user)
                    props.routerProps.history.push('/search')
                }
            })
        }else{
            setError("Please enter a valid email address")
        }
    }

    return(
        <div className="app">
            <div className="wrapper">
                <div className="form-div">
                    <h1>Signup</h1>
                    { error &&
                        <h3 style={{color: 'red'}}>
                            {error}  
                        </h3>
                    }
                    <form className='form signup' autoComplete="off">
                        <input type="text" onChange={(event) => setUsername(event.target.value)} placeholder="Username" name="Username"/>
                        <input type="text" onChange={(event) => setEmail(event.target.value)} placeholder="Email Address" name="email"/>
                        <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="Password" name="password"/>
                        <input type="password" onChange={(event) => setPasswordConfirmation(event.target.value)} placeholder="Confirm Password" name="password_confirmation"/>
                        <label>Date of Birth</label>
                        <input type="date" onChange={(event) => {setDob(event.target.value)}} placeholde="Date of Birth" name="dob"/>
                        <button className="button" onClick={handleSignup}>Signup</button>
                    </form>
                    <p>Already have an account? <Link to="/login"><button className='button'> Login</button></Link></p>
                </div>
                <Footer />
            </div>
        </div>
        
    )
}

export default connect(null,{
    setUser
})(SignupForm)