import React, { useState } from 'react'
import { PayPalButton } from "react-paypal-button-v2";

const Paypal = (props) => {

    const [tip, setTip] = useState("")
    const [paypalError, setPaypalError] = useState(null)
    const [paymentComplete , setPaymentComplete] = useState(false)


    const handleTipInput = (event) => {
        try{
            setTip(parseFloat(event.target.value))
        }catch(err){
            paypalError('Please enter a numerical value')
        }
    }

    const handleClose = (event) => {
        document.querySelector('canvas').style.zIndex = 0
        props.setDonate(false)
    }

    return(
        <div className="tip-jar-container">
                {
                    !paymentComplete ?
                        <div className="tip-jar">
                            <i className="times icon" onClick={handleClose}></i>
                            <h1>We greatly appreciate all contributions! We promise to keep improving this platform for everyone!</h1>
                            <div className="input-container">
                                <input className="tip-input" type="text" placeholder="$0.00" onChange={handleTipInput}/>
                            </div>
                            {
                                paypalError &&
                                <h3>{paypalError}</h3>
                            }
                            {
                                tip > 0 && 
                                <div className="paypal-buttons">
                                    <PayPalButton
                                        commit={false}
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                description: "Contribution to Happy Hour",
                                                purchase_units: [{
                                                    amount: {
                                                        currency_code: "USD",
                                                        value: tip
                                                    }
                                                }],
                                            })
                                        }}
                                        onApprove={(data,actions) => {
                                            actions.order.capture()
                                        }}
                                        onError={(error) => {
                                            setPaypalError(error)
                                        }
                                        }
                                        onSuccess = {(details) => {
                                            setPaymentComplete(true)
                                            setTimeout(() => {
                                                document.querySelector('canvas').style.zIndex = 0
                                                props.setDonate(false)
                                            }, 2000)
                                        }}
                                        style={{color: 'white',shape: 'pill',height: 40,}}
                                        options={{
                                            clientId: process.env.PRODUCTION_CLIENT_ID
                                        }}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <div className="payment-complete">
                            <h3>Thank you so much for your contribution!</h3>
                        </div>
                }
        </div>
    )
}

export default Paypal