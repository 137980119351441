import { SETUSER } from './types'

const setUser = (user) => {
   return({type: SETUSER, payload: user})
}

const autoLogin = (base_url) => {
    return async function(dispatch){
        await fetch(`${base_url}/auto_login`,{
            method: "GET",
            credentials: 'include',
            headers : { 
                'Accept': 'application/json'
               }
        })
        .then(resp => resp.json())
        .then(user =>{
            if(user.error){
                dispatch({type: SETUSER, payload: null, payload2: true})
            }else if(user.id){
                dispatch({type: SETUSER, payload: user, payload2: true})
            }
        })
    }
}

export {
    setUser,
    autoLogin
}