import React from 'react'

const Table = (props) => {
    return (
        <table style={{display: `${props.display}`}}>
            <tbody style={{margin: '0 auto', color: 'white'}}>
                <tr>
                    <th className="table-header">Days</th>
                    <th className="table-header">Hours</th>
                </tr>
                {
                    Object.keys(props.hours).map((day, i) => {
                        return <tr key={i}>
                            <td>{day}</td>
                            <td>{props.hours[day]}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    )
}

export default Table