import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'


const HomePage = (props) => {
    return(
        <div className="app">
            <div className="wrapper">
                <iframe title="Happy Hour"frameBorder="0" className="svg-image" src="https://gistcdn.githack.com/chrispinto1/b3579d1c604c9718bc8fd233efae5afd/raw/72582cde38096565fd6b8a2e31af9056328edb90/HappyHour.svg"/>
                <div className="options">
                    <Link to="login"><button  className="button">Login</button> </Link>
                    <Link to="signup"><button className="button">Signup</button> </Link>
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default HomePage