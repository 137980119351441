const SETUSER = "SETUSER"
const SETBARS = "SETBARS"
const SETMAP = "SETMAP"
const SETBASEURL = "SETBASEURL"

export {
    SETUSER,
    SETBARS,
    SETMAP,
    SETBASEURL
}