import React from 'react'

const Sidebar = (props) => {

    const handleClose = (event) => {
        if(event.target.className.includes('sign')){
            window.location.replace("/")
        }else if(!event.target.className.includes("sidebar-menu")){
            const sidebar = document.querySelector('.sidebar-menu')
            sidebar.style.transform = 'translateX(-100%)'
            const closeSidebar = setTimeout(() => {
                document.querySelector('.sidebar-container').style.display = 'none'
                clearTimeout(closeSidebar)
            },700)
        }
    }

    const handleOption = (handleFunction) => {
        const openModal = setTimeout(() => {
            handleFunction()
            clearTimeout(openModal)
        },700)
    }

    return(
        <div className="sidebar-container" onClick={handleClose}>
            <div className="sidebar-menu">
                <ul>
                    <li>
                        <i className="search icon" onClick={(event) => window.location.href.includes("search") ? handleClose(event) : null}></i>
                        Search
                    </li>
                    <li onClick={() => handleOption(() => props.setDonate(true))}>
                        <i className="dollar icon"></i>
                        Donate
                    </li>
                    <li className="signout-option" onClick={props.handleLogout}>
                        <i className="sign out icon"></i>
                        Logout
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;