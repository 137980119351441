import React from 'react'

const About = () => {
    document.title = "About"
    
    return (
        <div className="about background-info">
            <div className="about-container black-background">
                <h1>Welcome To Piriji</h1>
                <h1 className="subscript">Find Your Next Happy Hour</h1>
                <p>
                    Piriji is an app that helps you find happy hours near you. We also include all locations that serve alchol such as restaurants, pubs, bars, etc. We currently only have locations in Manhattan, New York and constantly adding bars everyday into our database. Our mission here at Piriji is to give free public access to proper and complete bar information. Also download our mobile app on the App store or Play store!
                </p>
            </div>
        </div>
    )
}

export default About