import React, { Fragment, useEffect, useState } from 'react'
/* Mapbox */
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector, useDispatch } from 'react-redux'

/* Redux */ 
import { setUser } from '../redux/action.js'
import { SETBARS } from '../redux/types'
import SavedBarsContainer from '../containers/SavedBarsContainer.js';
import LoadingScreen from '../components/LoadingScreen.js'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY

const Map = (props) => {

    const [filters, setFilters] = useState({
        happyhours: false,
        food: false,
        price: []
    })
    let filtersArray = []

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, setMap] = useState(null)
    const [geocoder, setGeocoder] = useState(null)
    
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const dispatch = useDispatch()
    let user = useSelector(state => state.user)
    let base_url = useSelector(state => state.base_url)
    const barResults = useSelector(state => state.bars)

    useEffect(() => {
        const { happyhours, food, price } = filters
        if(happyhours || food || price.length > 0){
            handleBarFilters()
        }else if(barResults){
            displayResults(barResults)
        }
        if(!map){
            const initMap = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/dark-v10?optimize=true',
                center: [-73.984474,40.759010],
                zoom: 13
            });

            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                placeholder: "Search by Address or by GPS location"
            })

            initMap.addControl(geocoder);
            setMap(initMap)
            setGeocoder(geocoder)
        }else{
            map.on('load', function(){
                setMapLoaded(true)
                positionSearchBarComponents()
                window.onresize = handleResponsiveButtons
                geocoder.on('result', function(e){
                    handleSearchResults(e)
                })
            })
        }
    })

    const handleSearchResults = (e) => {
        fetch(`${base_url}/search`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                latitude: e.result.geometry.coordinates[1],
                longitude: e.result.geometry.coordinates[0]
            })
        })
        .then(resp => resp.json())
        .then(bars => {
            if(document.querySelectorAll('.marker').length){
                removeMarkers()
            }
            if(bars.length){
                dispatch({type: SETBARS, payload: bars})
                handleFilterContainer()
                bars.forEach(bar => {
                    displayMarkers(bar)
                })
            }
        })
    }

    const removeMarkers = () => {
        document.querySelectorAll('.marker').forEach(marker => {
            marker.remove()
        })
    }

    const displayMarkers = (bar) => {
        const marker = document.createElement('div')
        marker.className= 'marker'
        const coordinates = [bar.longitude, bar.latitude]
        new mapboxgl.Marker(marker).setLngLat(coordinates).addTo(map);
        displayPopup(marker, bar, coordinates)
    }

    const displayPopup = (marker, bar, coordinates) => {
        const popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false, offset: 12 })
        const popupHTML =  
        `
            <a href=${bar.website_url} rel="noreferrer" target="_blank"><h1 class="bar-name">${bar.name}</h1></a>
            <h2>${bar.address}</h2>
            <i id='${bar.id}' class="heart outline icon"></i>
            <div class="button-hours">
                <button class="view-hours" data-name="hours" data-header="Hours of Operation">View Hours</button>
                ${bar.happyhours ? '<button class="happy-hour" data-name="happyhours" data-header="Happy Hours">Happy Hours</button>' : ''}
            </dv>
        `
        marker.addEventListener('mouseenter', function(e){
            if(document.querySelectorAll('.mapboxgl-popup').length){
                document.querySelector('.mapboxgl-popup').remove()
            }
            openPopup(popup, bar, popupHTML, coordinates)
        })
    }

    const openPopup = (popup, bar, popupHTML, coordinates) => {
        popup.setLngLat(coordinates).setHTML(popupHTML).addTo(map)
        if(user.bars.some(savedBar => savedBar.id === bar.id)){
            document.getElementById(`${bar.id}`).style.color = 'red'
        }
        popup._content.addEventListener('click', function(e){
            const selection = e.target.innerText
            if(e.target.classList[0].includes('heart')){
                handleLike(e,bar)
            }else if(selection === 'View Hours' || selection === 'Happy Hours'){
                let hoursHeader = e.target.dataset.header
                let hours = bar[e.target.dataset.name]
                if(Object.values(hours).some(hour => hour.includes(',') )){
                    popup._content.style.width  = 'fit-content'
                }
                popup.setHTML(
                    `
                        <div>
                            <h1 style="font-size: 15px">${hoursHeader}</h1>
                            <i class="times icon"></i>
                        </div>
                        <table style="display: flex">
                            <tbody class="hours-table" style="margin: 0 auto; color: white">
                            <tr>
                                <th className="table-header">Days</th>
                                <th className="table-header">Hours</th>
                            </tr>
                            </tbody>
                        </table>
                    `
                )
                for(let i = 0; i < days.length; i++){
                    const tr = document.createElement('tr')
                    tr.innerHTML +=
                    `
                        <th>${days[i]}</th>
                        <th>${hours[days[i]]}</th>
                        <br>
                    `
                    popup._content.querySelector('tbody').append(tr)
                }
                popup._content.querySelector('i').addEventListener('click', function(e){
                    openPopup(popup, bar, popupHTML, coordinates)
                })
            }
        })
        popup._content.addEventListener('mouseleave', function(e){
            popup.remove()
        })
    }

    const handleLike = (e,bar) => {
        if(user.bars.some(savedBar => savedBar.id === bar.id)){
            e.target.style.color = 'white'
        }else{
            e.target.style.color = 'red'
        }
        fetch(`${base_url}/like`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-type': 'application/json',
                'Accepts': 'application/json'
            },
            body: JSON.stringify({
                bar_id: bar.id,
                user_id: user.id
            })
        })
        .then(resp => resp.json())
        .then(updatedUser => {
            dispatch(setUser(updatedUser))
            user.bars.push(bar)
            handleSavedBarsContainer()
        })
    }

    const handleFilterContainer = () => {
        const searchContainer = document.querySelector('.mapboxgl-ctrl-geocoder.mapboxgl-ctrl')
        document.querySelector('.filters').setAttribute('style', `display:inline !important;left: ${(searchContainer.offsetWidth - 35).toString() + 'px'}`)
        const buttonDiv = document.querySelector('.mapboxgl-ctrl-top-right').firstChild
        const filtersDiv = document.querySelector('.filters')
        window.onresize = function(){
            filtersDiv.setAttribute('style', `display:inline !important;left: ${(searchContainer.offsetWidth - 35).toString() + 'px'}`)
            buttonDiv.style.left = (window.innerWidth - 29).toString() + 'px'
            buttonDiv.style.top = (window.innerHeight - 120).toString() + 'px'
            document.querySelector('.filters-container').style.width = (document.querySelector('.mapboxgl-ctrl-top-right').offsetWidth - 1).toString() + 'px'
            document.querySelector('.prices-container').style.left = document.querySelector('#price').offsetLeft.toString() + 'px'
            handleSavedBarsContainer()
        }
    }

    const handleSavedBarsContainer = () => {
        if(document.querySelectorAll('.saved-bar-card').length){
            const numberOfBarsSaved = document.querySelectorAll('.saved-bar-card').length
            const containerSize = (numberOfBarsSaved * 200) + (numberOfBarsSaved * 15)
            if(containerSize > (window.innerWidth * .95) ){
                document.querySelector('.saved-bars-container').style.overflow = 'auto'
            }else{
                document.querySelector('.saved-bars-container').style.overflow = 'unset'
            }  
        }
    }
    

    const positionSearchBarComponents = () => {
        const buttonDiv = document.querySelector('.mapboxgl-ctrl-top-right').firstChild
        buttonDiv.style.left = (window.innerWidth - 29).toString() + 'px'
        buttonDiv.style.top = (window.innerHeight - 120).toString() + 'px'
        handleSavedBarsContainer()
    }

    const handleResponsiveButtons = () => {
        const buttonDiv = document.querySelector('.mapboxgl-ctrl-top-right').firstChild
        buttonDiv.style.left = (window.innerWidth - 29).toString() + 'px'
        buttonDiv.style.top = (window.innerHeight - 120).toString() + 'px'
        handleSavedBarsContainer()
    }

    const handleFilters = () => {
        const filterContainer = document.querySelector('.filters-container')
        if(filterContainer.style.display === "flex"){
            filterContainer.style.display = 'none'
        }else{
            filterContainer.style.display = 'flex'
            document.querySelector('.prices-container').style.left = document.querySelector('#price').offsetLeft.toString() + 'px'
        }

    }

    const addPriceFilter = (priceValue) => {
        if(filters.price.includes(priceValue)){
            let newPriceArray = filters.price.filter(price => price !== priceValue)
            setFilters({...filters, price: newPriceArray})
        }else{
            setFilters({...filters, price: [...filters.price, priceValue]})
        }
    }

    const handleBarFilters = () => {
        Object.keys(filters).map(key => {
            if(key === "price"){
                if(filters[key].length > 0){
                    filters[key].forEach(price => {
                        filtersArray.push(price)
                    })
                }
            }else{
                if(filters[key]){
                    filtersArray.push(key)
                }
            }
            return filtersArray
        })
        let filteredResults = []
        if(filters.happyhours && filters.food && filters.price.length){
            filters.price.forEach(price => {
                if(filteredResults.length){
                    filteredResults = [...filteredResults, ...barResults.filter(bar => bar.price === price && bar.happyhours && bar.food)]
                }else{
                    filteredResults = barResults.filter(bar => bar.price === price && bar.happyhours && bar.food)
                }
            })
        }else if((filters.happyhours || filters.food) && filters.price.length){
            filters.price.forEach(price => {
                if(filteredResults.length){
                    filteredResults = [...filteredResults, ...barResults.filter(bar => (filters.happyhours ? bar.happyhours : bar.food) && bar.price === price && !filteredResults.some(filteredBar => filteredBar.id === bar.id))]
                }else{
                    filteredResults = barResults.filter(bar => (filters.happyhours ? bar.happyhours : bar.food) && bar.price === price)
                }
            })
        }else if(filters.happyhours || filters.food){
            filtersArray.forEach(filter => {
                if(filteredResults.length){
                    filteredResults = filteredResults.filter(bar => bar[filter])
                }else{
                    filteredResults = barResults.filter(bar => bar[filter])
                }
            })
        }
        else if(filters.price.length){
            filters.price.forEach(price => {
                if(filteredResults.length){
                    filteredResults = [...filteredResults, ...barResults.filter(bar => bar.price === price && !filteredResults.some(filteredBar => filteredBar.id === bar.id))]
                }else{
                    filteredResults = barResults.filter(bar => bar.price === price)
                }
            })
        }
        displayResults(filteredResults)
    }
    
    const displayResults = (bars) => {
        if(document.querySelectorAll('.marker').length){
            document.querySelectorAll('.marker').forEach(marker => {
                marker.remove()
            })
        }
        bars.forEach(bar => {
            displayMarkers(bar)
        })
    }

    return (
        <Fragment>
            <div id="map"></div>
            {
                !mapLoaded ?
                <LoadingScreen />
                :
                <div className="searchbar">
                    <i onClick={props.handleSidebar} style={{color: 'black'}} className="sidebar icon"></i>
                    <i onClick={handleFilters}className="filters sliders horizontal icon"></i>
                    <div className="filters-container" style={{width: `${document.querySelector('.mapboxgl-ctrl-top-right').offsetWidth - 1}px `}}>
                        <ul>
                            <li id="happyhours" onClick={() => setFilters({...filters, happyhours: !filters.happyhours})} style={{fontWeight: filters.happyhours ? 'bold' : 'initial'}}>Happy Hours</li>
                            <li id="food" onClick={() => setFilters({...filters, food: !filters.food})} style={{fontWeight: filters.food ? 'bold' : 'initial'}}>Food</li>
                            <div id="price">
                                <li>Price</li>
                                <div className="prices-container">
                                        <li onClick={() => addPriceFilter("$")} style={{fontWeight: filters.price.includes("$") ? 'bold' : 'initial'}}>$</li>
                                        <li onClick={() => addPriceFilter("$$")} style={{fontWeight: filters.price.includes("$$") ? 'bold' : 'initial'}}>$$</li>
                                        <li onClick={() => addPriceFilter("$$$")} style={{fontWeight: filters.price.includes("$$$") ? 'bold' : 'initial'}}>$$$</li>
                                        <li onClick={() => addPriceFilter("$$$$")} style={{fontWeight: filters.price.includes("$$$$") ? 'bold' : 'initial'}}>$$$$</li>
                                </div>
                            </div>
                        </ul>
                        <i onClick={handleFilters} className="times icon"></i>
                    </div>
                </div>
            }
            <SavedBarsContainer user={user}/>
        </Fragment>
    )
}

export default Map;