import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

    const [footerPressed, setFooterPressed] = useState(false)

    useEffect(() => {
        if(footerPressed){
            const footer = document.querySelector('.homepage-footer-desktop')
            const footerDiv = document.querySelector('.display-footer')
            footer.style.left = ((window.innerWidth - footer.offsetWidth) / 2) - 24 + 'px'

            window.onresize = function(){
                footer.style.left = ((window.innerWidth - footer.offsetWidth) / 2) - 24 + 'px'
                if(window.innerWidth > 700){
                    footerDiv.style.bottom = '60px'
                }else{
                    footerDiv.style.bottom = '95px'
                }
            }
        }
    },[footerPressed])

    const handleOpenFooter = () => {
        setFooterPressed(!footerPressed)
    }

    return(
        <Fragment>
            <div className="display-footer" onClick={handleOpenFooter} style={{bottom: footerPressed ? window.innerWidth < 700 ? '95px':  '60px': '10px' }}>
                <p>More Information</p>
                <i className='angle down icon'></i>
            </div>
            <footer className={footerPressed ?  "footer" : "display-none"}>
                <div className="homepage-footer-desktop">
                    <Link className="link" to="/info/about">
                        <p>About</p>
                    </Link>
                    <Link className="link" to="/info/how-it-works">
                        <p>How It Works</p>
                    </Link>
                    <Link className="link privacy-policy" to="/info/privacy-policy">
                        <p>Privacy Policy</p>
                    </Link>
                    <Link className="link" to="/info/blogs">
                        <p>Blogs</p>
                    </Link>
                    <Link className="link" to="/info/careers">
                        <p>Careers</p>
                    </Link>
                </div>
                <div className="homepage-footer-mobile">
                    <div>
                        <Link className="link" to="/info/about">
                            <p>About</p>
                        </Link>
                        <Link className="link" to="/info/blogs">
                            <p>Blogs</p>
                        </Link>
                    </div>
                    <div>
                        <Link className="link privacy-policy" to="/info/privacy-policy">
                            <p>Privacy Policy</p>
                        </Link>
                        <Link className="link" to="/info/how-it-works">
                            <p>How It Works</p>
                        </Link>
                    </div>
                    <Link className="link" to="/info/careers">
                        <p>Careers</p>
                    </Link>
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer