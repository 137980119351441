import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'


const PasswordReset = (props) => {

    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [validToken, setValidToken] = useState('')
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [resetMessage, setResetMessage] = useState('')
    let base_url = useSelector(state => state.base_url)

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        if(base_url && token){
            fetch(`${base_url}/password/confirm-token`,{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    token: token
                })
            })
            .then(resp => resp.json())
            .then(response => {
                if(response.status === 200){
                    setValidToken(token)
                }else{
                    setError(response.error)
                }
            })
        }
    },[validToken, resetMessage, base_url])

    const handlePassword = (password) => {
        setPassword(password)
    }

    const handleConfirmPassword = (passwordConfirmation) => {
        setPasswordConfirmation(passwordConfirmation)
    }

    const handleEmail = (email) => {
        setEmail(email)
    }

    const resetPassword = () => {
        if(password !== "" && password === passwordConfirmation){
            fetch(`${base_url}/password/reset`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    token: validToken,
                    password: password
                })
            })
            .then(response => response.json())
            .then(response => {
                if(response.status === 200){
                    props.routerProps.history.push('/login')
                }
            })
        }
    }

    const requestPasswordReset = () => {
        if(email !== ""){
            fetch(`${base_url}/password/forgot`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                })
            })
            .then(response => response.json())
            .then(response => {
                setResetMessage(response.message)
            })
        }
    }

    if(validToken !== ''){
        return(
            <div className="app">
                <div className="reset-form">
                    { error !== "" && 
                        <p>{error}</p>
                    }
                    <h1>Reset Password</h1>
                    <input type="password" placeholder="Password" onChange={(event) => handlePassword(event.target.value)}/>
                    <input type="password" placeholder="Password Confirmation" onChange={(event) => handleConfirmPassword(event.target.value)} />
                    <button className="button" onClick={resetPassword}>Reset Password</button>
                </div>
            </div>
        )
    }else{
        return(
            <div className="app">
                <div className="reset-form">
                    <h1>Reset Password</h1>
                    { error !== "" && 
                        <p>{error}</p>
                    }
                    {
                        resetMessage !== "" &&
                        <p className="reset-message">{resetMessage}</p>
                    }
                    <input type="text" placeholder="Email" onChange={(event) => handleEmail(event.target.value)} />
                    <button className="button" onClick={requestPasswordReset}>Reset Password</button>
                </div>
            </div>
        )
    }
    
}


export default PasswordReset