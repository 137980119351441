import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setUser } from '../redux/action'
import { connect } from 'react-redux'
import Footer from '../components/Footer'


const LoginForm = (props) => {

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState(null)
    let base_url = useSelector(state => state.base_url)
    let user = useSelector(state => state.user)

    useEffect(()=> {
        document.title = props.title
        if(user){
            props.routerProps.history.push('/search')
        }
    })

    const login = (event) => {
        event.preventDefault()
        fetch(`${base_url}/login`,{
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(resp => resp.json())
        .then(user => {
            if(user.error){
                setError(user.error)
            }else{
                props.setUser(user)
                props.routerProps.history.push("/search")
            }
        })
    }

    return(
        <div className="app">
            <div className="wrapper">
                <div className="form-div">
                    <h1>Login</h1>
                    { error && 
                        <h3 style={{color: 'red'}}>
                            {error}
                        </h3>
                    }
                    <form className="form" autoComplete="off">
                        <input type="text" onChange={(event) => setEmail(event.target.value)} placeholder="Email Address" name="email"></input>
                        <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="Password" name="password"></input>
                        <button type="submit" onClick={login} className="button">Login</button>
                    </form>
                    <div className="user-options">
                        <div className="first-button">
                            <p>Forgot your password? <Link to="/reset-password"><button className="button">Reset Password</button></Link></p>
                            <p>Don't have an account? <Link to="/signup"><button className="button">Sign Up</button></Link></p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default connect(null,{
    setUser
})(LoginForm)