import { SETUSER, SETBARS, SETMAP, SETBASEURL } from './types'

const defaultState = {
    user: null,
    bars: null,
    base_url: null,
    fetched_user: false,
}

function reducer(prevState=defaultState, action){
    switch(action.type){
        case SETUSER: 
            return {...prevState, user: action.payload, fetched_user: action.payload2}
        case SETBASEURL:
            return {...prevState, base_url: action.payload}
        case SETBARS:
            return {...prevState, bars: action.payload}
        case SETMAP: 
            return {...prevState, map: action.payload}
        default:
            return prevState
    }
}

export default reducer