import React from 'react'

const MessagePopup = (props) => {

    const handleClose = () => {
        props.setCookie();
    }

    return (
        <div className="popup-message-container">
            <div className="popup-message">
                <i className="times icon" onClick={handleClose}></i>
                <h3>Thanks for using Happy Hour to find your next bar! We just wanted to let you know, we currently only have bars from 42nd st Time Square down to bowling green. We are actively gathering more information on bars and will update you when new locations are added to our database.</h3>
            </div>
        </div>
    )
    
}

export default MessagePopup