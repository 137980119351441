import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './App.css';
import { Switch, Route } from 'react-router-dom'
import HomePage from './containers/HomePage'
import SearchPage from './containers/SearchPage'
import LoginPage from './containers/LoginPage'
import SignupPage from './containers/SignupPage'
import ResetPassword from './components/ResetPassword'
import InfoPage from './containers/Info/InfoPages'
import { SETBASEURL } from './redux/types';
import { autoLogin } from './redux/action'

const App = () => {

  let base_url = useSelector(state => state.base_url)
  let user = useSelector(state => state.user)
  let fetched_user = useSelector(state => state.fetched_user)
  const dispatch = useDispatch()

  useEffect(() => {
    if(window.innerWidth < 476){
      document.querySelector('body').style.height = window.innerHeight + 'px'
    }
    if(!base_url){
      if(process.env.NODE_ENV === 'production'){
        dispatch({type: SETBASEURL, payload: 'https://pirijiapi.com'})
      }else if(process.env.NODE_ENV === 'development'){
        dispatch({type: SETBASEURL, payload: 'http://localhost:3000'})
      }
    }
    if(!fetched_user && !user && base_url){
      dispatch(autoLogin(base_url))
    }
  },)

    return (
      <Switch>
          <Route exact path="/" render={(routerProps) => <HomePage routerProps={routerProps}/>} />
          <Route exact path="/login" render={(routerProps) => <LoginPage routerProps={routerProps} title="Login"/>} />
          <Route exact path="/signup" render={(routerProps) => <SignupPage routerProps={routerProps} title="Signup"/>}/>
          <Route exact path="/search" render={(routerProps) => <SearchPage routerProps={routerProps} title="Search"/> } />
          <Route exact path="/reset-password" render={(routerProps) => <ResetPassword routerProps={routerProps} title="Search"/> } />
          <Route path="/info" component={InfoPage} />
      </Switch>
    );
}

export default App