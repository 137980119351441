import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import SavedBars from '../components/SavedBars'

const SavedBarsContainer = () => {

    let headerRef = useRef(null)
    let speechBubbleRef = useRef(null);
    const [displayBars, setDisplayBars] = useState('flex')
    const [messageText, setMessageText] = useState('Hide Saved Bars')
    let user = useSelector(state => state.user)

    const showMessage = () => {
        document.querySelector('.speech-bubble').style.display = 'block'
    }

    const hideMessage = () => {
        document.querySelector('.speech-bubble').style.display = 'none'
    }

    const hideSavedArea = (event) => {
        setDisplayBars('none')
        setMessageText('Show Saved Bars')
        event.target.style.display = 'none'
        document.querySelector('.show-button').style.display = 'block'
        headerRef.current.classList.add('hidden')
        speechBubbleRef.current.classList.add('show-bars')
    }

    const showSavedBars = (event) => {
        event.target.style.display = 'none'
        document.querySelector('.hide-button').style.display = 'block'
        speechBubbleRef.current.classList.remove('show-bars')
        headerRef.current.classList.remove('hidden')
        if(headerRef.current.style.bottom === "260px"){
            headerRef.current.style.bottom = '150px'
            speechBubbleRef.current.style.bottom = "180px"
        }
        setMessageText('Hide Saved Bars')
        setDisplayBars('flex')
    }

    const updateUserBars = (barID) => {
        const updatedArray = user.bars.filter(bars => bars.id !== barID)
        user.bars = updatedArray
    }

    return (
        user &&
            user.bars.length > 0 &&
                <div>
                    <p className="speech-bubble" ref={speechBubbleRef}>{messageText}</p>
                    <div className="saved-bars-header" ref={headerRef}>
                        <h1><u>Saved Bars</u></h1>
                        <i className="hide-button minus square icon" onClick={(event) => hideSavedArea(event)} onMouseOver={() => showMessage()} onMouseOut={() => hideMessage()}></i>
                        <i className="show-button plus square icon" onClick={(event) => showSavedBars(event)} onMouseOver={() => showMessage()} onMouseOut={() => hideMessage()}></i>
                    </div>
                    {
                        displayBars !== 'none' &&
                            <div className="saved-bars-container" style={{display: `${displayBars}`}}>
                                {
                                    user.bars.map(bar => {
                                        return  <SavedBars bar={bar} key={bar.id} updateUser={updateUserBars} speechBubbleRef={speechBubbleRef} headerRef={headerRef}/>
                                    })
                                }
                            </div>
                    }
                </div>
    )
}

export default SavedBarsContainer