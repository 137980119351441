import React, { useEffect } from 'react'

const InfoHome = (props) => {

    useEffect(() => {
        props.history.push('/info/about')
    },[])

    return(
        <div style={{backgroundColor: 'rgba(27,27,27)'}}>

        </div>
    )
}

export default InfoHome