import React from 'react'
import PrivacyPolicy from '../../components/Info/PrivacyPolicy'
import { Route } from 'react-router-dom'
import NavBar from '../../components/Info/NavBar'
import InfoHome from '../../components/Info/Home'
import About from '../../components/Info/About'


const InfoPages = () => {
    return(
        <div>
            <NavBar />
            <Route exact path="/info" component={InfoHome} />
            <Route exact path="/info/about" component={About} />
            <Route exact path="/info/privacy-policy" component={PrivacyPolicy}/>
        </div>
    )
}

export default InfoPages